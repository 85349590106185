import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ResultsTable from './ResultsTable';
import {getHttpUrl, getWebSocketUrl} from "./server/SERVER";
import {setRoom as setRoomGlobal} from "./server/SERVER";
import {useParams} from "react-router-dom";
import {webSocket} from "./App";

const QuestionSubmitter = () => {
    const { room } = useParams();
    const [questionText, setQuestionText] = useState('');
    const [correctAnswers, setCorrectAnswers] = useState('');
    const [answer, setAnswer] = useState('');
    const [password, setPassword] = useState('');
    const [results, setResults] = useState(null);
    const [question, setQuestion] = useState(null);
    const [finishedQuestion, setFinishedQuestion] = useState(false);
    const [submittedQuestion, setSubmittedQuestion] = useState(false);
    const [autoSubmit, setAutoSubmit] = useState(true);
    const [autoLoadAnswer, setAutoLoadAnswer] = useState(true);

    function connectWebSocket() {
        // create the WebSocket connection
        let webSocket = new WebSocket(getWebSocketUrl());

        webSocket.onmessage = function (event) {
            if (event.data === "ping") {
                webSocket.send('pong');
                return;
            }

            let message = JSON.parse(event.data);
            if (message.type === "question") {
                setQuestion(message);
                setResults(null);
            }
            if (message.type === "openquestion") {
                setQuestionText(message.question);
                setAnswer(message.answer.trim());
                const correctAnswers = updateCorrectAnswers(message.answer.trim());
                if (autoSubmit) {
                    submitQuestion(message.question, correctAnswers, message.answer.trim());
                }
                if (autoLoadAnswer) {
                    loadAnswerFromAI(undefined, message.question);
                }
            }
            if (message.type === "results-update") {
                setResults(message.results);
            }
        };

        webSocket.onclose = function(event) {
            console.log('WebSocket connection closed, reconnecting...');
            // Attempt to reconnect
            setTimeout(connectWebSocket, 2000); // Retry connection after 2 seconds
        };

    }
    useEffect(() => {
        setRoomGlobal(room);
        connectWebSocket();
        setPassword(localStorage.getItem('questionCreatorPassword'));
    }, [autoSubmit, room]);

    const updateCorrectAnswers = (answer) => {
        let answerLines = answer.split("\n");
        if (answerLines.length > 0) {
            let answerLine = answerLines[0];
            if (answerLine.startsWith("Answer")) {
                const regex = /[a-h](?=\))/g;
                const matches = answerLine.match(regex);
                const result = matches.join(",");
                setCorrectAnswers(result);
                return result;
            }
        }
        return correctAnswers;
    };

    useEffect(() => {
        setFinishedQuestion(false);
        setSubmittedQuestion(false);
    }, [question]);

    const sendAnswer = (event) => {
        async function sendAnswer() {
            await axios.post(getHttpUrl() + '/answer', {answer});
        }
        sendAnswer();
        event.preventDefault();
    }

    const loadAnswerFromAI = (event, questionText) => {
        const apiKey = "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";

        async function generateQuiz() {
            const openAIUrl = 'https://api.openai.com/v1/chat/completions';

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            };

            const data = {
                model: 'gpt-3.5-turbo',
                messages: [
                    {
                        role: 'user',
                        content: "Provide the answer and detailed explanation: "+ questionText.replaceAll("\n"," "),
                    },
                ],
            };

            try {
                const response = await axios.post(openAIUrl, data, { headers: headers });
                const generatedText = response.data.choices[0].message.content.trim();
                setAnswer(generatedText);
            } catch (error) {
                console.error('Error occurred while making the request:', error);
            }
        }

        generateQuiz();
        if (event) event.preventDefault();
    }

    const finishQuestion = async () => {
        await axios.post(getHttpUrl()+'/finish');
        setFinishedQuestion(true);
        setSubmittedQuestion(false);
    }

    // submit for manual and auto-submit
    const submitQuestion = async (questionText, correctAnswers, answerText) => {
        // Parse the correct answers
        const correctAnswersArray = correctAnswers.split(',').map((answer) => answer.trim());

        // Parse the question text and options
        const lines = questionText.trim().split('\n');
        const question = lines[0].trim();
        const options = lines.slice(1).map((line) => line.slice(3).trim());

        // Create the payload
        const payload = {
            question,
            options,
            answer: answerText,
            correct: options.filter((option, index) => correctAnswersArray.includes(String.fromCharCode(97 + index))),
        };

        try {
            // Send the POST request
            const response = await axios.post(getHttpUrl()+'/question', payload);
            setSubmittedQuestion(true);
        } catch (error) {
            console.error('Error while submitting the question:', error);
        }
    }

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        if (submittedQuestion) {
            await finishQuestion();
        }
        // Store the password in localStorage
        localStorage.setItem('questionCreatorPassword', password);

        await submitQuestion(questionText, correctAnswers, answer);
    };
    return (
        <div>
            <h1>JQUIZ: Create a Question for Quiz</h1>
            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div>
                    <button type="submit" style={{ marginBottom: "5px" }}>{submittedQuestion?"Finish Previous Question & Submit New":"Submit"} Question</button>
                    <span style={{marginLeft: '20px'}}>Auto-submit {autoSubmit?"on":"off"}
                        <input type="checkbox" onChange={(e)=> {setAutoSubmit(as=>!as)}} checked={autoSubmit} /></span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <label htmlFor="question-text" style={{ display: "block", marginBottom: "5px" }}>Question:</label>
                    <textarea
                        id="question-text"
                        value={questionText}
                        onChange={(e) => { setQuestionText(e.target.value); setAnswer(""); setCorrectAnswers("")}}
                        rows="7"
                        cols="200"
                        required
                        style={{ width: "100%" }}
                    />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <label htmlFor="correct-answers" style={{ display: "block", marginBottom: "5px" }}>Correct Answers (e.g., "a,c"):</label>
                    <input
                        type="text"
                        id="correct-answers"
                        value={correctAnswers}
                        onChange={(e) => setCorrectAnswers(e.target.value)}
                        required
                    />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <label htmlFor="question-text" style={{ display: "block", marginBottom: "5px" }}>Answer:</label>
                    <div>
                        <button onClick={loadAnswerFromAI}>Load answer from AI</button>
                        <button onClick={sendAnswer}>Send answer</button>
                        <span style={{marginLeft: '20px'}}>Auto load answer {autoLoadAnswer?"on":"off"}
                            <input type="checkbox" onChange={(e)=> {setAutoLoadAnswer(as=>!as)}} checked={autoLoadAnswer} /></span>
                    </div>
                    <textarea
                        id="answer-text"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        rows="7"
                        cols="200"
                        style={{ width: "100%" }}
                    />
                </div>
                {/*<div style={{ marginBottom: "10px" }}>*/}
                {/*    <label htmlFor="password" style={{ display: "block", marginBottom: "5px" }}>Password:</label>*/}
                {/*    <input*/}
                {/*        type="password"*/}
                {/*        id="password"*/}
                {/*        value={password}*/}
                {/*        onChange={(e) => setPassword(e.target.value)}*/}
                {/*        required*/}
                {/*    />*/}
                {/*</div>*/}
            </form>

            <button disabled={finishedQuestion} onClick={finishQuestion} style={{ marginBottom: "20px" }}>Finish question</button>
            {finishedQuestion && <span> <b>This question is finished!</b></span>}
            {question && results && results.length > 0 && (
                <>
                    <ResultsTable question={question} results={results} trainerMode={true}/>
                </>
            )}
        </div>
    );
};

export default QuestionSubmitter;

import React, {memo, useEffect, useState} from "react";

const QuizQuestion = memo(({
                            question,
                            showResults,
                            onSubmit,
                            creatorMode,
                            onOptionSelect,
                            onQuestionChange,
                            onQuestionContentEdit,
                            onOptionContentEdit,
                            hiddenMode = false,
                           }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [choiceIsSent, setChoiceIsSent] = useState(false);

    useEffect(() => {
        setSelectedOptions([]);
        setChoiceIsSent(false);
    }, [question]);

    const handleOptionSelect = (option) => {
        if (choiceIsSent) return;
        if (showResults) return;
        if (question.correct.length>1) {
            setSelectedOptions((prevOptions) =>
                prevOptions.includes(option)
                    ? prevOptions.filter((opt) => opt !== option)
                    : [...prevOptions, option]
            );
        } else {
            setSelectedOptions([option]);
        }
    };

    // in the creator mode, show correct answers after loading the question
    useEffect(() => {
        if (creatorMode) {
            setSelectedOptions(question.correct);
        }
    }, [question, creatorMode]);

    useEffect(() => {
        if (onOptionSelect === undefined) return;
        // find the order of the selected options in all options
        const selectedOptionsIndexes = selectedOptions.map((option) =>
            question.options.findIndex((opt) => opt === option)
        );
        // map the order of the selected options to letter a) or b)
        const selectedOptionsLetters = selectedOptionsIndexes.map((index) =>
            String.fromCharCode(97 + index)+")"
        );
        // join all letters to a string
        const selectedOptionsString = selectedOptionsLetters.join(",");
        onOptionSelect(selectedOptionsString);
    }, [selectedOptions]);

    const handleSubmit = () => {
        if (selectedOptions.length === 0) return;
        setChoiceIsSent(true);
        onSubmit(selectedOptions);
    };

    return (
        <div>
            <h2 contentEditable={true}
                suppressContentEditableWarning={true}
                onBlur={(e)=>onQuestionContentEdit(e.target.textContent)}>
                {question.question}
            </h2>
            <form >
                {question.options.map((option) => (<div key={option}>
                    {creatorMode ? (
                        <div style={{padding: 5}}>
                            <span style={{paddingTop:3}}><input
                                type={question.correct.length>1 ? "checkbox" : "radio"}
                                name="option"
                                value={option}
                                checked={!hiddenMode && selectedOptions.includes(option)}
                                onChange={() => handleOptionSelect(option)}
                            /></span>
                        <span contentEditable={true}
                            suppressContentEditableWarning={true}
                            style={{marginLeft: 10, fontSize: "1.2rem", textAlign: "left"}}
                            onBlur={(e)=>onOptionContentEdit(option, e.target.textContent)}>

                            {option}
                        </span>
                    </div>) : (
                    <label
                        key={option}
                        style={{
                            display: "flex",
                            cursor: "pointer",
                            padding: "10px",
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#eef";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                        }}
                    >
                        <span style={{paddingTop:3}}><input
                            type={question.correct.length>1 ? "checkbox" : "radio"}
                            name="option"
                            value={option}
                            checked={!hiddenMode && selectedOptions.includes(option)}
                            onChange={() => handleOptionSelect(option)}
                        /></span>

                        <span
                            style={{marginLeft: 10, fontSize: "1.2rem", textAlign: "left"}}
                        >
                            {option}
                        </span>
                    </label>)}
                </div>))}
            </form>
            {!showResults && !creatorMode && (
            <button onClick={handleSubmit}
                    className="button-style"
                    disabled={choiceIsSent}>
                Send your choice!
            </button>)}
        </div>
    );
});

export default QuizQuestion;

import React, {useEffect, useRef} from "react";
import "./ResultsTable.css";

const ResultsTable = ({ results, question, active = true, trainerMode = false }) => {
    // For inactive questions, if there are results, render them, but if there are no results, render nothing.
    // if (!active && (!results || results.length === 0)) {
    //     return null;
    // }
    let tableRef = useRef();
    useEffect(()=>{
        if (!trainerMode) tableRef.current.scrollIntoView();
    });

    const getVoters = (option, questionResults) => {
        return questionResults
            .filter((participant) => participant.answers.includes(option))
            .map((participant) => participant.name)
            .join(", ");
    };

    const getVotersNumber = (option, questionResults) => {
        let number = questionResults
            .filter((participant) => participant.answers.includes(option))
            .map(participant => 1)
            .reduce((a,b)=>a+b, 0);
        return number > 0 ? number : "";
    };

    const isOptionCorrect = (option, correctOptions) => {
        return correctOptions.includes(option);
    };

    const getVoteCount = (option, questionResults) => {
        return questionResults.filter((participant) =>
            participant.answers.includes(option)
        ).length;
    };

    const getMaxVoteCount = (questionResults) => {
        const voteCounts = question.options.map((option) =>
            getVoteCount(option, questionResults)
        );
        return Math.max(...voteCounts);
    };

    return (
        <>
        <h2>Results table:</h2>
        <table className="results-table" ref={tableRef}>
            <thead>
            <tr>
                <th style={{textAlign:"center"}}>Option</th>
                {trainerMode && <th>Voters</th>}
                <th>Correct</th>
                <th>Votes Distribution</th>
            </tr>
            </thead>
            <tbody>
            {question.options.map((option, index) => {
                const questionResults = results;
                const correctOptions = question.correct;
                const voteCount = getVoteCount(option, questionResults);
                const maxVoteCount = getMaxVoteCount(questionResults);

                return (
                    <tr key={option} className={index % 2 === 0 ? "even-row" : ""}>
                        <td>{option}</td>
                        {trainerMode && <td>{getVoters(option, questionResults)}</td>}
                        <td style={{textAlign:"center"}}>{isOptionCorrect(option, correctOptions) ? "Yes" : "No"}</td>
                        <td style={{textAlign:"center"}}>
                            <div
                                style={{
                                    backgroundColor: "rgba(158,47,255,0.64)",
                                    width: `${(voteCount / maxVoteCount) * 100}%`,
                                    height: "20px",
                                    textAlign: "center",
                                    color: "white"
                                }}
                            >{getVotersNumber(option, questionResults)}</div>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
        </>
    );
};

export default ResultsTable;

import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import QuizQuestion from "./QuizQuestion";
import {UserContext} from "./App";
import ResultsTable from "./ResultsTable";
import stopwatch2 from "./assets/stopwatch2.gif";
import {getHttpUrl, getWebSocketUrl} from "./server/SERVER";

const Quiz = ({ room, questions, onFinishQuiz }) => {
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [score, setScore] = useState(0);
  const { name, setName, email, setEmail } = useContext(UserContext);

  useEffect(() => {
    async function fetchActiveQuestion() {
      const url = getHttpUrl();
      console.log("fetchActiveQuestion url: ", url);
      let res = await fetch(url+"/active-question");
      let json = await res.json();
      //debugger
      if (json.activeQuestion) {
        setQuestion(json.activeQuestion);
        //if (json.activeAnswer) setAnswer(json.activeAnswer);
      }
    }
    fetchActiveQuestion();
  }, []);

  function connectWebSocket() {
    let webSocket = new WebSocket(getWebSocketUrl());
    webSocket.onmessage = function (event) {
      if (event.data === "ping") {
        webSocket.send('pong');
        return;
      }
      let message = JSON.parse(event.data);
      if (message.type === "question") {
        setQuestion(message);
        setResults(null);
        setShowResults(false);
        setAnswer(message.answer);
      }
      if (message.type === "results-update") {
        setResults(message.results);
      }
      if (message.type === "finish") {
        setShowResults(true);
      }
      if (message.type === "answer") {
        setAnswer(message.answer);
      }
    };

    webSocket.onclose = function(event) {
      console.log('WebSocket connection closed, reconnecting...');
      // Attempt to reconnect
      setTimeout(connectWebSocket, 2000); // Retry connection after 2 seconds
    };
  }

  useEffect(() => {
    connectWebSocket();
  }, []);

  const handleQuestionSolved = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    } else {
      setScore(score - 1);
    }
  };

  const handleSubmit = useCallback((answers) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, answers }),
    };
    fetch(getHttpUrl()+"/results", requestOptions).then((response) => {
      if (response.ok) {
        setShowResults(true);
      }
    });
  },[]);

  const answerLines = question && question.answer ?
      question.answer.split('\n').map((line, index) => (
      <div key={index} style={{textAlign: 'justify', marginBottom: '10px'}}>
        {line}
        <br />
      </div>
  )) : null;


  return (
      <div>
        {question && (
            <div>
              <QuizQuestion showResults={showResults}
                            question={question}
                            onSubmit={handleSubmit} />
            </div>
        )}
        {showResults && results && results.length > 0 && (
            <>
              <ResultsTable results={results} question={question} />
              {question.answer && <h2>Answer:</h2>}
              <div style={{}}>{answerLines}</div>
            </>
        )}
        {showResults && !results && <>
          <h2>This question voting is finished, waiting for the next question...</h2>
          <img src={stopwatch2} width={200}/>
        </>}
        {!question && (
            <div>
              <h2 style={{color:"#4345B8"}}>Waiting for the next question...</h2>
              <img src={stopwatch2} width={200}/>
            </div>
        )}
      </div>
  );
};

export default Quiz;

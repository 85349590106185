import React, {useState, useCallback, useEffect, useRef} from "react";
import "./QuizCreator.css";
import QuizQuestion from "./QuizQuestion";
import ResultsTable from "./ResultsTable";
import axios from "axios";
import {getHttpUrl, getWebSocketUrl, setRoom} from "./server/SERVER";
import {useParams} from "react-router-dom";
let socket = null;

const QuizCreator = () => {
    const { room } = useParams();
    const [questions, setQuestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [inputEditMode, setInputEditMode] = useState(false);
    const [results, setResults] = useState({});
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [gptRequest, setGptRequest] = useState("");
    const [questionsNumber, setQuestionsNumber] = useState(1);
    const [webSocketClientId, setWebSocketClientId] = useState(null);
    const [hiddenMode, setHiddenMode] = useState(false);

    function connectWebSocket() {
        // create the WebSocket connection
        let webSocket = new WebSocket(getWebSocketUrl());
        webSocket.onmessage = function (event) {
            if (event.data === "ping") {
                webSocket.send('pong');
                return;
            }
            let message = JSON.parse(event.data);
            if (message.question && message.type === "results-update") {
                setResults(prevResults => ({
                    ...prevResults,
                    [message.question]: message.results,
                }));
            }
            if (message.type === "chat-gpt-streaming") {
                setInputValue(v=>v+message.content);
            }
            if (message.type === "set-id") {
                setWebSocketClientId(message.id);
            }
        }

        webSocket.onclose = function (event) {
            console.log('WebSocket connection closed, reconnecting...');
            // Attempt to reconnect
            setTimeout(connectWebSocket, 2000); // Retry connection after 2 seconds
        };

    }

    useEffect(() => {
        setRoom(room);
        connectWebSocket();
    }, []);

    const parseQuestions = (questionsText) => {
        let questionSections = [];
        let section = [];
        const lines = questionsText.split('\n').filter(line => line.trim() !== '');

        // Separate questions by "Explanation:"
        lines.forEach((line, index) => {
            section.push(line);
            if (line.trim().startsWith("Explanation:") || index === lines.length - 1) {
                questionSections.push(section);
                section = [];
            }
        });

        return questionSections.map((questionLines) => {
            let question = "";
            let options = [];
            let correctAnswersArray = [];
            let correct = [];
            let answer = "";

            questionLines.forEach((line, index) => {
                if (index === 0) {
                    // The first line should be the question
                    question = line.trim();
                } else if (line.trim().startsWith("Answer:")) {
                    // The line starting with "Answer:" contains the correct answers
                    correctAnswersArray = line.split(":")[1]
                        .trim()
                        .replace(" and ", ", ")
                        .replace(") ", ")")
                        .split(",")
                        .map(answer => answer[0]); // "a,b,c" or "a and b"
                    correct = options.filter((option, index) => correctAnswersArray.includes(String.fromCharCode(97 + index)));
                } else if (line.trim().startsWith("Explanation:")) {
                    // The line starting with "Explanation:" contains the explanation
                    answer = line.slice(13).trim();
                } else {
                    // Other lines should be the options
                    options.push(line.slice(3).trim());
                }
            });

            return { question, options, correct, answer };
        });
    };

    const handleParseClick = useCallback(() => {
        const parsedQuestions = parseQuestions(inputValue);
        setQuestions(parsedQuestions);
    }, [inputValue]);

    useEffect(handleParseClick, [inputValue]);

    const handleGPTRequest = useCallback((event) => {
        setGptRequest(event.target.value);
    }, []);

    const handleInputChange = useCallback((event) => {
        setInputValue(event.target.value);
    }, []);

    const handleQuestionContentUpdate = (questionToUpdate) => {
        let updatedInputValue = inputValue.split('\n').map((line, index) => {
            if (line.includes(questionToUpdate)) {
                return questionContentRef.current[questionToUpdate];
            }
            return line;
        }).join('\n');

        setInputValue(updatedInputValue);
    };

    const handleQuestionAnswersUpdate = (questionToUpdate, selectedOptionsString) => {
        if (inputEditMode || selectedOptionsString === "") return;
        let isQuestionToUpdate = false
        let updatedInputValue = inputValue.split('\n').map((line, index) => {
            if (line.includes(questionToUpdate)) {
                isQuestionToUpdate = true;
            }
            if (isQuestionToUpdate && line.startsWith("Answer:")) {
                let newAnswers = selectedOptionsString;
                isQuestionToUpdate = false;
                return `Answer: ${newAnswers}`;
            }
            return line;
        }).join('\n');
        // also update the questions to show the correct answers
        // setQuestions(prevQuestions => {
        //     return prevQuestions.map((question) => {
        //         if (questionToUpdate === question.question) {
        //             return {
        //                 ...question,
        //                 correct: question.options.filter(
        //                     (option, index) => selectedOptionsString.includes(String.fromCharCode(97 + index))
        //                 )
        //             }
        //         }
        //         return question;
        //     });
        // });
        setInputValue(updatedInputValue);
    };

    const submitQuestion = async (question) => {

        try {
            setActiveQuestion(question);
            // Send the POST request
            const response = await axios.post(getHttpUrl()+'/question', question);
        } catch (error) {
            console.error('Error while submitting the question:', error);
        }
    };

    const handleQuestionTextChange = (oldQuestionText, newQuestionText) => {
        // replace the old question text with the new question text in inputValue
        let updatedInputValue = inputValue.replace(oldQuestionText, newQuestionText);
        // update inputValue
        setInputValue(updatedInputValue);

        // update the questions to show the updated question text
        setQuestions(prevQuestions => {
            return prevQuestions.map((question) => {
                if (oldQuestionText === question.question) {
                    return {
                        ...question,
                        question: newQuestionText,
                    }
                }
                return question;
            });
        });
    }

    const handleOptionTextChange = (questionText, oldOptionText, newOptionText) => {
        // find the line with the question
        let isOptionSection = false;
        let updatedInputValue = inputValue.split('\n').map((line, index, arr) => {
            if (line.includes(questionText)) {
                isOptionSection = true;
            } else if (isOptionSection && (line.startsWith("a)") || line.startsWith("b)") || line.startsWith("c)") || line.startsWith("d)"))) {
                if (line.includes(oldOptionText)) {
                    return line.replace(oldOptionText, newOptionText);
                }
            } else if (isOptionSection && !(line.startsWith("a)") || line.startsWith("b)") || line.startsWith("c)") || line.startsWith("d)"))) {
                // we have moved out of the options section, no longer check for options
                isOptionSection = false;
            }
            return line;
        }).join('\n');

        // update inputValue
        setInputValue(updatedInputValue);

        // update the questions to show the updated option text
        setQuestions(prevQuestions => {
            return prevQuestions.map((question) => {
                if (questionText === question.question) {
                    return {
                        ...question,
                        options: question.options.map(option =>
                            option === oldOptionText ? newOptionText : option
                        )
                    }
                }
                return question;
            });
        });
    }

    const handleGenerateQuestions = async () => {
        const generateQuestionsRequest = `
            Generate a quiz on the topic above.  
            Quiz must contain ${questionsNumber} questions. 
            Provide 4 possible options for the answer with the short texts.
              After each question, add the options, 
             then Answer: <the correct answers> 
             then Explanation: <the detailed explanation of correct and incorrect answers, on single line>. 

             Questions should have more than one correct answer. 
             Options should be started from a),b),c),d). 
            `;
        // generate questions with Chat GPT by submitting the input value
        setInputValue("");
        let response = await axios.post(getHttpUrl()+'/stream-gpt?clientId='+webSocketClientId,
            {request: gptRequest+generateQuestionsRequest});
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <textarea
                      style={{ width: "80%", height: "50px" }}
                      value={gptRequest}
                      onChange={handleGPTRequest}
                      placeholder={"Enter the topic or the text for which you want to generate questions"}
                  />
                    <div style={{ display: 'flex', alignItems: 'center',marginBottom: "10px", }}>
                        <button style={{marginRight:"30px"}} onClick={handleGenerateQuestions}>Generate Questions</button>
                         Questions number:
                        <select value={questionsNumber} onChange={(e) => setQuestionsNumber(e.target.value)}>
                            {[...Array(10)].map((e, i) => <option key={i+1} value={i+1}>{i+1}</option>)}
                        </select>
                    </div>
                  <textarea
                      style={{ width: "80%", height: "500px" }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onFocus={setInputEditMode.bind(null, true)}
                      onBlur={setInputEditMode.bind(null, false)}
                      placeholder={"Paste your questions here"}
                  />
                <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center',marginBottom: "10px", }}>
                <button onClick={handleParseClick}>Parse the questions</button>
                <input style={{ marginLeft: "20px"}} type="checkbox" id="hidden-mode" checked={hiddenMode} onChange={() => setHiddenMode(!hiddenMode)} />
                <label htmlFor="hidden-mode">Hidden Mode</label>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
            </div>

            {questions && questions.map((question, index) => (
                <div key={index} className={`question ${question === activeQuestion ? 'active' : ''}`}>
                    <QuizQuestion
                        question={question}
                        showResults={hiddenMode}
                        hiddenMode={hiddenMode}
                        correctAnswers={question.correct}
                        creatorMode={true}
                        onOptionSelect={handleQuestionAnswersUpdate.bind(null, question.question)}
                        onQuestionContentEdit={handleQuestionTextChange.bind(null, question.question)}
                        onOptionContentEdit={handleOptionTextChange.bind(null, question.question)}
                        onBlur={handleQuestionContentUpdate.bind(null, question.question)}
                    />
                    {/* Explanation added after options */}
                    {!hiddenMode && question.answer && (
                        <div className="question-explanation">
                            Explanation: {question.answer}
                        </div>
                    )}
                    {activeQuestion !== question ? (
                        <div style={{display: "flex", justifyContent: "center" }}>
                            <button className="submit-button" onClick={()=>submitQuestion(question)}>Submit Question</button>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center" }} className="submitted">
                            The question is submitted
                            <span className="link" onClick={()=>submitQuestion(question)}>Resubmit Question</span>
                        </div>
                    )}
                    {/* Render the votes for each question */}
                    {!hiddenMode && question && results[question.question] && (
                        <ResultsTable
                            question={question}
                            results={results[question.question]}
                            active={question === activeQuestion}
                            trainerMode={true}
                        />
                    )}
                </div>
            ))}
        </div>
    );

};

export default QuizCreator;

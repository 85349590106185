import React, { useEffect, useState } from "react";
import {HTTPURL} from "./server/SERVER";
import {useParams} from "react-router-dom";

const ScoreTable = () => {
    const [data, setData] = useState([]);
    const toms = [
        { tomId: 1, quizIds: [1, 1000, 2], title: "Java Collections", month: "April 23"},
        { tomId: 2, quizIds: [3, 4], title: "Java Collections Multithreaded", month: "May 23"},
        { tomId: 3, quizIds: [5, 6], title: "Asynchronous Java", month: "June 23"},
        { tomId: 4, quizIds: [7, 8], title: "Microservices Data management", month: "August 23"},
        { tomId: 5, quizIds: [9, 10], title: "Microservices Communication", month: "November 23"},
    ];
    const { tomId } = useParams();
    const tom = toms.find(q => q.tomId === Number(tomId));

    const sendEmail = (email) => {
        window.open(`mailto:${email}`, "_blank");
    };

    const sortData = (data) => {
        return data.sort((a, b) => {
            if (b.sumOfPercents === a.sumOfPercents) {
                return a.name.localeCompare(b.name);
            }
            return b.sumOfPercents - a.sumOfPercents;
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`https://jtutor.app/users/scores/${tom.quizIds.join(",")}`);
            const result = await response.json();
            //const sortedData = sortData(result);
            setData(result);
        };
        fetchData();
    }, []);

    const findScore = (scores, quizId) => {
        const scoreObj = scores.find((score) => Number(score.quizId) === quizId);
        return scoreObj ? scoreObj : { score: "", percent: "" };
    };

    return (
        <div>
            <h1 style={{color:'rgba(178, 71, 255)', textAlign:'center'}}>
                {`Score Table for Topic of ${tom.month}: ${tom.title}`}
            </h1>
            <table className="results-table">
                <thead>
                <tr>
                    <th>Place</th>
                    <th>User name</th>
                    <th>Email</th>
                    <th>Score Quiz 1</th>
                    <th>%</th>
                    <th>Score Quiz 2</th>
                    <th>%</th>
                    <th>Score Quiz 3</th>
                    <th>%</th>
                    <th>Summary %</th>
                </tr>
                </thead>
                <tbody>
                {data.map((user, index) => (
                    <tr key={user._id} className={index % 2 === 0 ? "odd-row" : "even-row"}>
                        <td>{index + 1}</td>
                        <td className="name-cell"
                            onClick={()=>sendEmail(user._id)}>{user.name}</td>
                        <td className="email-cell">
                            {user._id}
                        </td>
                        {tom.quizIds.map((quizId) => {
                            const { score, percent } = findScore(user.scores, quizId);
                            return (
                                <>
                                    <td>{score}</td>
                                    <td>{percent}{percent?'%':''}</td>
                                </>
                            );
                        })}
                        <td style={{fontWeight: 'bold'}}>{user.sumOfPercents}%</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ScoreTable;

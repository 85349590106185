export const data = [
  {
    _id: "642b562f0696bbc4db425800",
    username: "test4",
    email: "text4@dxc.com",
    score: 15,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "test4",
    email: "text4@dxc.com",
    score: 4,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "test6",
    email: "text6@dxc.com",
    score: 6,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "Vladimir Sonkin",
    email: "Vlad@dxc.com",
    score: 6,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "Vladimir",
    email: "vladimir.sonkin123@dxc.com",
    score: 10,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "Mary",
    email: "maria.varenova.455@dxc.com",
    score: 15,
  },
  {
    _id: "642b562f0696bbc4db425800",
    username: "Vladimir Sonkin",
    email: "Vlad@dxc.com",
    score: 15,
  },
];


// export const WSURL = "wss://jquiz-athjd4btb4c0fadd.z01.azurefd.net:8081";
// export const WSURL = "ws://209.38.252.152:8081";
export const WSURL = "wss://jtutor.app/ws";
// export const WSURL = "ws://localhost:8081";

// export const HTTPURL = "https://jquiz-athjd4btb4c0fadd.z01.azurefd.net";
export const HTTPURL = "https://jtutor.app";

// export const HTTPURL = "http://localhost:9000";

let room = "1";
export const setRoom = (_room) => { room = _room; };
export const getHttpUrl = () => HTTPURL + "/"+ room;
export const getWebSocketUrl = () => WSURL + "?room="+ room;

import "./styles.css";
import StartForm from "./StartForm";
import {createContext, useEffect, useState} from "react";
import UserInfo from "./UserInfo";
import UserTable from "./UserTable";
import {createHashRouter, RouterProvider, useParams,} from "react-router-dom";
import QuestionSubmitter from "./QuestionSubmitter";
import {getWebSocketUrl, setRoom} from "./server/SERVER";
import ScoreTable from "./ScoreTable";
import QuizCreator from "./QuizCreator";

export const UserContext = createContext();

const Main = () => {
  const { room } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [finishResults, setFinishResults] = useState(null);
  const handleStartQuiz = () => {
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
  };
  useEffect(() => {
    setRoom(room);
    const storedName = localStorage.getItem("name");
    const storedEmail = localStorage.getItem("email");

    if (storedName) {
      setName(storedName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const onFinishQuiz = async (score) => {
    const results = { username: name, email, score };
    try {
      const response = await fetch(HTTPURL + "/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(results),
      });
      if (response.ok) {
        setFinishResults("Results are submitted!");
      }
    } catch (err) {
      setFinishResults("Results were not submitted: " + err);
    }
  };

  const userContextValue = {
    name,
    setName,
    email,
    setEmail,
  };
  return (
    <div className="App">
      <UserContext.Provider value={userContextValue}>
        <UserInfo />
        <StartForm onStartQuiz={handleStartQuiz} onFinishQuiz={onFinishQuiz} />
        <div>{finishResults}</div>
        {finishResults && <UserTable currentUserEmail={email} />}
      </UserContext.Provider>
      <div className="roomInfo"><b>Room:</b> {room}</div>
    </div>
  );
};
const router = createHashRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/results",
    element: <UserTable />,
  },
  {
    path: "/submitter/:room",
    element: <QuestionSubmitter />,
  },
  {
    path: "/scores/:tomId",
    element: <ScoreTable />,
  },
  {
    path: "/creator/:room",
    element: <QuizCreator />,
  },
  {
    path: "/:room",
    element: <Main />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};
export default App;
